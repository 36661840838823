.App {
	text-align: center;
}

/* * {
	 background-color: rgb(43, 43, 43); 
	 color: #fff; 
} */

.App-logo {
	height: 40vmin;
	pointer-events: none;
	background-color: #61dafb;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
	color: #fff;
	background-color: rgba(0, 174, 255, 0.456);
}

.table thead th {
	text-transform: uppercase;
	color: #fff;
	background-color: rgb(0, 58, 85);
}

.table thead tr {
	font-size: 14px;
	box-shadow: none;
	border: none;
}

.table tbody tr {
	font-size: 12px;
	text-align: center;
	color: rgb(255, 255, 255);
	margin: 0;
	border: none;
	box-shadow: none;
}

.navbar-container {
	font-size: 20px;
	color: rgb(255, 255, 255);
	background-color: rgb(0, 122, 175);
}

.navbar-brand {
	color: #fff !important;
}
.navbar-default .navbar-brand {
	color: #fff;
}
.navbar-inverse .navbar-brand {
	color: #fff;
}
.navbar-light .navbar-brand {
	color: #fff;
	background-color: inherit;
}
.navbar-light .navbar-link {
	color: #fff;
	background-color: rgb(0, 122, 175);
}
.menubar-link {
	color: #444;
	background-color: transparent;
}
.menubar-link:hover {
	color: #fff;
}

.icon-button {
	color: #fff;
	background-color: transparent;
}

.table tbody tr:nth-child(odd) {
	background-color: rgb(0, 86, 94);
}

.table tbody tr:nth-child(even) {
	background-color: rgb(0, 56, 61);
}

.Nav {
	font-size: 20px;
	color: white;
	background-color: #61dafb;
}

.transaction-tool-row {
	background-color: #fff;
}

.transaction-date-col {
	/* 	color: #fff; */
	/* background-color: #555 !important; */
	border: 0px solid crimson;
}

.pageTitle {
	width: 180px;
	padding: 5px 0px 5px 0px;
	color: rgb(232, 232, 232);
	background-color: rgb(0, 58, 85);

	font-size: 0.7em;
	font-weight: bolder;
	display: flex;
	/* justify-items: end; */
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	border: 0px solid crimson;
}

.nav-link-text {
	color: rgb(249, 249, 249);
}

.nav-link-text:hover {
	color: rgb(221, 255, 0);
}

.transactions-table {
	height: 500px;
	margin-bottom: 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-collapse: collapse;
}

/*
::-webkit-scrollbar {
	width: 10px;
}

 ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgb(16, 0, 105);
	border: 1px solid rgb(16, 0, 105);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: rgb(0, 109, 119);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: #61dafb;
}
*/
.transactions-table-head th {
	position: sticky;
	top: 0;
	border-collapse: collapse;
}
.transaction-table-body td {
	border-collapse: collapse;
}
.transactions-table-head th:nth-child(2) {
	width: 80px;
}
.transactions-table-head th:nth-child(3) {
	width: 120px;
}
.transactions-table-head th:nth-child(4) {
	width: 100px;
}
.transactions-table-head th:nth-child(5) {
	text-align: left;
}
.transactions-table-head th:nth-child(6) {
	text-align: right;
}

.transactions-table-body td:nth-child(2) {
	width: 80px;
}
.transactions-table-body td:nth-child(3) {
	width: 120px;
}
.transactions-table-body td:nth-child(4) {
	width: 100px;
	text-align: left;
}
.transactions-table-body td:nth-child(5) {
	text-align: left;
}
.transactions-table-body td:nth-child(6) {
	text-align: right;
}
.transaction-table-body td:nth-child(7) {
	color: rgb(255, 255, 255);
	background-color: inherit;
}

.transaction-button-div {
	display: flex;
	justify-content: center;
	background-color: transparent;
}

.transaction-edit-button {
	color: rgb(255, 255, 255);
	background-color: rgb(0, 140, 200);
	border: none;
}
.transaction-edit-button:hover {
	color: rgb(255, 255, 255);
	background-color: rgb(200, 0, 43);
	border: none;
}

.transaction-delete-button {
	color: rgb(255, 255, 255);
	background-color: rgb(0, 140, 200);
	border: none;
	border: 0px solid crimson;
}

.transaction-delete-button:hover {
	color: rgb(255, 255, 255);
	background-color: rgb(200, 0, 43);
}

.pagination-col {
	background-color: transparent;
}
.paginationBttns {
	width: 50%;
	height: 25px;
	list-style: none;
	display: flex;
	justify-content: center;
	color: rgb(255, 255, 255);
	background-color: transparent;
}

.paginationBttns a {
	padding: 5px;
	margin: 0px;
	text-decoration: none;
	border-radius: 0px;
	/* border: 1px solid #2b2eff; */
	border: 0px solid rgb(0, 58, 85);
	color: rgb(0, 140, 200);
	cursor: pointer;
	background-color: rgb(0, 58, 85);
}

.paginationBttns a:hover {
	color: white;
	background-color: rgb(0, 140, 200);
}

.paginationActive a {
	color: white;
	background-color: rgb(0, 140, 200);
}

.paginationDisabled a {
	color: #444;
	visibility: hidden;
}

.paginationDisabled a:hover {
	color: #444;
}

.monthPicker {
	max-width: 100px;
	text-align: left;
	font-size: 0.8em;
	color: invert(1) !important;
	border: none;
	/* background-color: invert(1); */
}

.transaction-search-col {
	display: flex;
	align-items: center;
}

.transaction-search {
	width: 180px;
	padding-left: 5px;
	font-size: 0.8em;
	display: flex;
	/* justify-items: end; */
	justify-content: start;
	align-items: center;
	color: rgba(232, 232, 232);
	background-color: rgb(0, 58, 85);
	border: 1px solid rgb(0, 58, 85);
	border-radius: 10px;
}

.transaction-search-icon {
	margin-left: 5px;
	font-size: 0.8em;
	display: flex;
	justify-content: center;
	align-items: center;
}

.updown-button {
	font-size: 0.8em;
	background-color: rgb(0, 58, 85);
	border: 0px solid crimson;
}

.updown-button:hover {
	background-color: rgb(0, 140, 200);
	border-radius: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.input-amount {
	text-align: right;
}

.saveButton:disabled {
	color: #222 !important;
	background-color: #333;
}

.modal-row-head {
	font-size: 0.9em;
}
.modal-row-body {
	font-size: 0.8em;
}

.transactions-total {
	width: 100px;
	margin-top: 0px;
	margin-left: 10px;
	padding: 10px 0px 10px 0px;
	color: rgb(255, 255, 255);
	font-weight: bolder;
	text-align: right;
	background-color: rgb(0, 58, 85);
}

.transactions-label {
	width: 150px;
	padding-right: 20px;
	font-weight: 500;
	background-color: transparent;
}
.transactions-label-amount {
	background-color: transparent;
}

.login-area {
	width: 200px;
	height: 100px;
	padding: 10px;
}

.user-status {
	width: 200px;
	font-size: 0.7em;
	text-align: left;
	background-color: transparent;
	border: 0px solid crimson;
}

.user-status label {
	padding-right: 10px;
	background-color: transparent;
}

.settings-label {
	padding: 10px 0px;
	margin-bottom: 10px;
	color: #fff;
	background-color: rgb(0, 58, 85);
}

.settings-content-label {
	width: 100%;
	padding: 10px 0px;
	margin-bottom: 10px;
	color: #fff;
	background-color: rgb(0, 58, 85);
}
